import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { pagesUrl } from '../pagesUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qoute',
  templateUrl: './qoute.component.html',
  styleUrls: ['./qoute.component.css']
})
export class QouteComponent implements OnInit {

  obj = { fullname: null, email: null, phone: null, comapanyname: null, location: null, budget: null, message: null }

  constructor(public http: HttpClient) { }

  ngOnInit(): void {

  }

  submitForm() {
    swal.showLoading();
    this.http.post<any>(pagesUrl.qoute.add, this.obj).subscribe(
      res => {
        // this.data = res.data;
        swal.fire('Process Complete', res.message, 'success');
        this.resetForm();
      },
      (err) => {
        console.log(err);
        swal.fire('Process Unsuccessful', err.error.message, 'error');
      }
    );
  }


  resetForm() {
    this.obj = {
      fullname: null, email: null, phone: null, comapanyname: null, location: null, budget: null, message: null
    };
  }

}
