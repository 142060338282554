<!-- Start of Banner section
        ============================================= -->
<section id="dia-banner" class="dia-banner-section position-relative">
  <!-- <div class="banner-side-img banner-img1 position-absolute">
    <img src="assets/img/d-agency/banner/ns2.png" alt="" />
  </div>
  <div class="banner-side-img banner-img2 position-absolute">
    <img src="assets/img/d-agency/banner/ns1.png" alt="" />
  </div> -->
  <div class="container">
    <div class="dia-banner-content dia-headline pera-content">
      <span class="dia-banner-tag">We are Trigonal</span>
      <h1 class="cd-headline clip is-full-width">
        We help to solve clients
        <span class="cd-words-wrapper">
          <b class="is-visible">Problem.</b>
          <b>Wants.</b>
          <b>Issue.</b>
        </span>
      </h1>
      <p>
        Our teams help you reach your goal through the use of cutting edge
        technologies and business model.
      </p>
      <div class="dia-banner-btn d-flex">
        <!-- <div class="dia-play-btn text-center d-inline-block">
                    <a href="https://www.youtube.com/watch?v=kxPCFljwJws" class="lightbox-image overlay-box"><i
                            class="fas fa-play"></i></a>
                </div> -->
        <div class="dia-abt-btn text-center d-inline-block">
          <a routerLink="/about-us">About Our Company</a>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="banner-shape1 position-absolute">
    <img src="assets/img/d-agency/shape/pls2.png" alt="" />
  </div> -->
  <div
    class="banner-shape2 position-absolute"
    data-parallax='{"y" : 100, "rotateY":500}'
  >
    <img src="assets/img/d-agency/shape/shp1.png" alt="" />
  </div>
  <!-- <div class="banner-side-shape2 position-absolute" data-parallax='{"y" : -30}'>
    <img src="assets/img/d-agency/shape/bsi2.png" alt="" />
  </div>
  <div class="banner-side-shape1 position-absolute" data-parallax='{"y" : 30}'>
    <img src="assets/img/d-agency/shape/bsi1.png" alt="" />
  </div> -->
</section>
<!-- End of Banner section
        ============================================= -->

<!-- Start of Service section
        ============================================= -->
<section id="dia-service" class="dia-service-section">
  <div class="container">
    <div class="dia-service-content">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="dia-service-img position-relative">
            <img src="assets/img/d-agency/service/s1.png" alt="" />
            <div class="dia-service-shape dia-service-shape1 position-absolute">
              <img src="assets/img/d-agency/service/s2.png" alt="" />
            </div>
            <div class="dia-service-shape dia-service-shape2 position-absolute">
              <img src="assets/img/d-agency/service/s3.png" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="dia-service-text">
            <div
              class="dia-section-title text-left text-capitalize dia-headline"
            >
              <span>service</span>
              <h2>Services That We Serve With Extra Care.</h2>
            </div>

            <div class="dia-service-details clearfix">
              <div
                class="dia-service-item dia-headline ul-li-block wow fadeFromUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <h3>Web Designing</h3>
                <ul>
                  <li>User Interface Design</li>
                  <li>User Experience Design</li>
                  <li>App Design</li>
                  <li>Theme Layout Design</li>
                </ul>
              </div>

              <div
                class="dia-service-item dia-headline ul-li-block wow fadeFromUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <h3>Digital Marketing</h3>
                <ul>
                  <li>SEO/SEM</li>
                  <li>Online Marketing</li>
                  <li>Social Media Marketing</li>
                  <li>Email Marketing></li>
                  <li>Mobile Marketing</li>
                </ul>
              </div>
              <div
                class="dia-service-item dia-headline ul-li-block wow fadeFromUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <h3>Software Development</h3>
                <ul>
                  <li>Enterprise Solution</li>
                  <li>App Development</li>
                  <li>E-commerce</li>
                  <li>Email Template Development</li>
                </ul>
              </div>
            </div>
            <div class="dia-service-btn">
              <div class="dia-service-more text-center float-left">
                <a routerLink="/our-services">View More Service</a>
              </div>
              <!-- <div class="dia-service-review float-right">
                <div class="dia-service-rate ul-li">
                  <ul>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                  </ul>
                  <div class="dia-service-rate-text">
                    <span>2125 tasks</span> completed by our team with
                    <span>99%</span> 5-star reviews
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End of Service section
        ============================================= -->

<section class="work-category pad-tb tilt3d">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 v-center">
        <div class="common-heading text-l">
          <span>Industries we work for</span>
          <h2>Helping Businesses in All Domains</h2>
          <p>
            Where you are in the product lifecycle, we can jump in to
            accompany and guide you through every step anywhere from conception
            all the way, regardless of where you are in the
            product or service lifecycle, we can hop in to guide you through
            each progression, from conception to implementation.
          </p>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="work-card-set">
          <div
            class="icon-set wow fadeIn"
            data-wow-delay=".2s"
            style="
              visibility: visible;
              animation-delay: 0.2s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd1">
              <div class="icon-bg">
                <i class="far fa-comment"></i>
              </div>
              <p>Social Networking</p>
            </div>
          </div>
          <div
            class="icon-set wow fadeIn"
            data-wow-delay=".4s"
            style="
              visibility: visible;
              animation-delay: 0.4s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd2">
              <div class="icon-bg">
                <i class="fas fa-bullhorn"></i>
              </div>
              <p>Digital Marketing</p>
            </div>
          </div>
          <div
            class="icon-set wow fadeIn"
            data-wow-delay=".6s"
            style="
              visibility: visible;
              animation-delay: 0.6s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd3">
              <div class="icon-bg">
                <i class="fas fa-cart-arrow-down"></i>
              </div>
              <p>Ecommerce Development</p>
            </div>
          </div>
          <div
            class="icon-set wow fadeIn"
            data-wow-delay=".8s"
            style="
              visibility: visible;
              animation-delay: 0.8s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd4">
              <div class="icon-bg">
                <i class="fas fa-video"></i>
              </div>
              <p>Video Service</p>
            </div>
          </div>
          <div
            class="icon-set wow fadeIn"
            data-wow-delay="1s"
            style="
              visibility: visible;
              animation-delay: 1s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd5">
              <div class="icon-bg">
                <i class="fas fa-university"></i>
              </div>
              <p>Banking Service</p>
            </div>
          </div>
          <div
            class="icon-set wow fadeIn"
            data-wow-delay="1.2s"
            style="
              visibility: visible;
              animation-delay: 1.2s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd6">
              <div class="icon-bg">
                <i class="fas fa-server"></i>
              </div>
              <p>Enterprise Service</p>
            </div>
          </div>
          <div
            class="icon-set wow fadeIn"
            data-wow-delay="1.4s"
            style="
              visibility: visible;
              animation-delay: 1.4s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd7">
              <div class="icon-bg">
                <i class="fas fa-book"></i>
              </div>
              <p>Education Service</p>
            </div>
          </div>
          <div
            class="icon-set wow fadeIn"
            data-wow-delay="1.6s"
            style="
              visibility: visible;
              animation-delay: 1.6s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd8">
              <div class="icon-bg">
                <i class="fas fa-plane"></i>
              </div>
              <p>Tour and Travels</p>
            </div>
          </div>
          <div
            class="icon-set wow fadeIn"
            data-wow-delay="1.8s"
            style="
              visibility: visible;
              animation-delay: 1.8s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd9">
              <div class="icon-bg">
                <i class="fas fa-heartbeat"></i>
              </div>
              <p>Health Service</p>
            </div>
          </div>
          <div
            class="icon-set wow fadeIn"
            data-wow-delay="2s"
            style="
              visibility: visible;
              animation-delay: 2s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd10">
              <div class="icon-bg">
                <i class="fas fa-ticket-alt"></i>
              </div>
              <p>Event &amp; Ticket</p>
            </div>
          </div>
          <div
            class="icon-set wow fadeIn"
            data-wow-delay="2.2s"
            style="
              visibility: visible;
              animation-delay: 2.2s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd11">
              <div class="icon-bg">
                <i class="fas fa-utensils"></i>
              </div>
              <p>Restaurant Service</p>
            </div>
          </div>
          <div
            class="icon-set wow fadeIn"
            data-wow-delay="2.4s"
            style="
              visibility: visible;
              animation-delay: 2.4s;
              animation-name: fadeIn;
            "
          >
            <div class="work-card cd12">
              <div class="icon-bg">
                <i class="fas fa-briefcase"></i>
              </div>
              <p>Business Consultant</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Start of Experience section
        ============================================= -->
<section id="dia-exp" class="dia-exp-section">
  <div class="container">
    <div class="dia-exp-content">
      <div class="row">
        <div
          class="col-lg-6 col-md-12 wow fadeFromLeft"
          data-wow-delay="0ms"
          data-wow-duration="1500ms"
        >
          <div class="dia-exp-img text-center position-relative">
            <img src="assets/img/d-agency/about/ab2.png" alt="" />
            <div
              class="ab-shape1 position-absolute"
              data-parallax='{"x" : -30}'
            >
              <img src="assets/img/d-agency/shape/as1.png" alt="" />
            </div>
            <div class="ab-shape2 position-absolute" data-parallax='{"x" : 30}'>
              <img src="assets/img/d-agency/shape/as1.png" alt="" />
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 wow fadeFromRight"
          data-wow-delay="300ms"
          data-wow-duration="1500ms"
        >
          <div class="dio-exp-text-area">
            <div class="dia-exp-text">
              <div class="dia-about-title-text">
                <div
                  class="
                    dia-section-title
                    text-left text-capitalize
                    dia-headline
                  "
                >
                  <span>Our Experiences</span>
                  <h2>Leading IT Solution Development Company</h2>
                </div>
                <!-- <div class="dia-about-text">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt.
                                </div> -->
              </div>
            </div>
            <div class="skill-progress-bar">
              <div class="skill-set-percent dio-headline">
                <h4>Business Development</h4>
                <div class="progress">
                  <div class="progress-bar" data-percent="90"></div>
                </div>
              </div>
              <div class="skill-set-percent dio-headline">
                <h4>Research / Analytics</h4>
                <div class="progress">
                  <div class="progress-bar" data-percent="92"></div>
                </div>
              </div>
              <div class="skill-set-percent dio-headline">
                <h4>Development</h4>
                <div class="progress">
                  <div class="progress-bar" data-percent="95"></div>
                </div>
              </div>
              <div class="skill-set-percent dio-headline">
                <h4>Customer Service / Support</h4>
                <div class="progress">
                  <div class="progress-bar" data-percent="99.9"></div>
                </div>
              </div>
            </div>
            <div class="dia-exp-btn text-center">
              <a href="#dia-team">Our Team</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End of Experience section
        ============================================= -->

<!-- Start of Experience section
        ============================================= -->
<!-- <section id="dia-portfolio" class="dia-portfolio-section">
    <div class="container">
        <div class="dia-section-title text-center text-capitalize dia-headline">
            <span>Awesome Portfolio</span>
            <h2>We have a great portfolio to check</h2>
        </div>
    </div>
    <div class="dia-portfolio-content">
        <div id="dia-portfolio-slider" class="dia-portfolio-slide-area owl-carousel">
            <div class="dia-portfolio-img-text">
                <div class="dia-portfolio-img">
                    <img src="assets/img/d-agency/portfolio/port1.jpg" alt="">
                </div>
                <div class="dia-portfolio-text dia-headline text-center">
                    <h3><a href="#">Out Sourcing Philippines</a></h3>
                    <span>UI/UX Design</span>
                </div>
            </div>
            <div class="dia-portfolio-img-text">
                <div class="dia-portfolio-img">
                    <img src="assets/img/d-agency/portfolio/port2.jpg" alt="">
                </div>
                <div class="dia-portfolio-text dia-headline text-center">
                    <h3><a href="#">Out Sourcing Philippines</a></h3>
                    <span>UI/UX Design</span>
                </div>
            </div>
            <div class="dia-portfolio-img-text">
                <div class="dia-portfolio-img">
                    <img src="assets/img/d-agency/portfolio/port3.jpg" alt="">
                </div>
                <div class="dia-portfolio-text dia-headline text-center">
                    <h3><a href="#">Out Sourcing Philippines</a></h3>
                    <span>UI/UX Design</span>
                </div>
            </div>
            <div class="dia-portfolio-img-text">
                <div class="dia-portfolio-img">
                    <img src="assets/img/d-agency/portfolio/port4.jpg" alt="">
                </div>
                <div class="dia-portfolio-text dia-headline text-center">
                    <h3><a href="#">Out Sourcing Philippines</a></h3>
                    <span>UI/UX Design</span>
                </div>
            </div>
            <div class="dia-portfolio-img-text">
                <div class="dia-portfolio-img">
                    <img src="assets/img/d-agency/portfolio/port5.jpg" alt="">
                </div>
                <div class="dia-portfolio-text dia-headline text-center">
                    <h3><a href="#">Out Sourcing Philippines</a></h3>
                    <span>UI/UX Design</span>
                </div>
            </div>
            <div class="dia-portfolio-img-text">
                <div class="dia-portfolio-img">
                    <img src="assets/img/d-agency/portfolio/port1.jpg" alt="">
                </div>
                <div class="dia-portfolio-text dia-headline text-center">
                    <h3><a href="#">Out Sourcing Philippines</a></h3>
                    <span>UI/UX Design</span>
                </div>
            </div>
            <div class="dia-portfolio-img-text">
                <div class="dia-portfolio-img">
                    <img src="assets/img/d-agency/portfolio/port2.jpg" alt="">
                </div>
                <div class="dia-portfolio-text dia-headline text-center">
                    <h3><a href="#">Out Sourcing Philippines</a></h3>
                    <span>UI/UX Design</span>
                </div>
            </div>
        </div>
        <div class="dia-port-more text-center">
            <a class="d-block text-center" href="#">View All</a>
        </div>
    </div>
</section> -->
<!-- End of Experience section
        ============================================= -->

<!-- Start Client -->

<!-- <section id="dia-client" class="dia-client-section">
  <div class="container">
    <div
      class="
        dia-section-title
        text-center text-capitalize
        pera-content
        dia-headline
      "
    >
      <h2>Some of our Clients</h2>
      <p>
        Our clients love us and have good testimony about us because of our good
        and excellent customer care and support. We never leave our clients
        hanging.
      </p>
    </div>
    <div class="ei-partner-content">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
          <img
            src="assets/img/clients/unesco.png"
            alt="United Nations Educational, Scientific and Cultural Organization"
          />
        </ng-template>
        <ng-template carouselSlide>
          <img
            src="assets/img/clients/nmec.png"
            alt="National Commission for Mass Education"
          />
        </ng-template>
        <ng-template carouselSlide>
          <img
            src="assets/img/clients/nis.png"
            alt="Nigeria Immigration Service"
          />
        </ng-template>
        <ng-template carouselSlide>
          <img
            src="assets/img/clients/team.png"
            alt="Technics, Engineering, Architecture Marketing Limited"
          />
        </ng-template>
        <ng-template carouselSlide>
          <img src="assets/img/clients/worldbank.png" alt="World Bank" />
        </ng-template>
        <ng-template carouselSlide>
          <img src="assets/img/clients/yobe.png" alt="Yobe State Government" />
        </ng-template>
        <ng-template carouselSlide>
          <img src="assets/img/clients/cbn.png" alt="Central Bank of Nigeria" />
        </ng-template>
        <ng-template carouselSlide>
          <img
            src="assets/img/clients/cmfb.png"
            alt="Consumer MicroFinance Bank"
          />
        </ng-template>
      </owl-carousel-o>
    </div>

    <div class="-cta-btn mt70">
      <div
        class="free-cta-title v-center wow zoomInDown"
        data-wow-delay="1.2s"
        style="
          visibility: visible;
          animation-delay: 1.2s;
          animation-name: zoomInDown;
        "
      >
        <p>We <span>Promise.</span> We <span>Deliver.</span></p>
        <a routerLink="qoute" class="btn-main bg-btn2 lnk"
          >Let's Work Together<i class="fas fa-chevron-right fa-icon"></i
          ><span class="circle"></span
        ></a>
      </div>
    </div>
  </div>
</section> -->

<!-- End Clients -->

<!-- Start of Team section
        ============================================= -->
<section id="dia-team" class="dia-team-section">
  <div class="container">
    <div
      class="
        dia-section-title
        text-center text-capitalize
        pera-content
        dia-headline
      "
    >
      <!-- <span>Our Experts</span> -->
      <h2>Our Experts</h2>
      <p>
        Our team is your team. When your mission is to be better, faster and
        smarter, you need the best people driving your vision forward.
      </p>
    </div>
    <div id="dia-team-slide" class="dia-team-content owl-carousel">
      <div class="dia-team-pic-text">
        <div class="dia-team-img position-relative">
          <div class="team-mem-img-ei text-center position-relative">
            <img src="assets/img/d-agency/team/tm1.png" alt="" />
            <span class="mshape-bg shape-bg1"
              ><img src="assets/img/d-agency/team/msbg1.png" alt=""
            /></span>
            <span class="mshape-bg shape-bg2"
              ><img src="assets/img/d-agency/team/msbg2.png" alt=""
            /></span>
          </div>
          <div class="dia-team-social">
            <a href="#"><i class="fab fa-facebook-f"></i></a>
            <a href="#"><i class="fab fa-instagram"></i></a>
            <a href="#"><i class="fab fa-linkedin"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
          </div>
        </div>
        <div class="dia-team-text dia-headline text-center pera-content">
          <h3><a href="#">Chinedu Agulanna</a></h3>
          <p>Vice President</p>
        </div>
      </div>

      <div class="dia-team-pic-text">
        <div class="dia-team-img position-relative">
          <div class="team-mem-img-ei text-center position-relative">
            <img src="assets/img/d-agency/team/tm1.png" alt="" />
            <span class="mshape-bg shape-bg1"
              ><img src="assets/img/d-agency/team/msbg1.png" alt=""
            /></span>
            <span class="mshape-bg shape-bg2"
              ><img src="assets/img/d-agency/team/msbg2.png" alt=""
            /></span>
          </div>
          <div class="dia-team-social">
            <a href="#"><i class="fab fa-facebook-f"></i></a>
            <a href="#"><i class="fab fa-instagram"></i></a>
            <a href="#"><i class="fab fa-linkedin"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
          </div>
        </div>
        <div class="dia-team-text dia-headline text-center pera-content">
          <h3><a href="#">Olalekan Abiola</a></h3>
          <p>Managing Director</p>
        </div>
      </div>
      <div class="dia-team-pic-text">
        <div class="dia-team-img position-relative">
          <div class="team-mem-img-ei text-center position-relative">
            <img src="assets/img/d-agency/team/tm2.png" alt="" />
            <span class="mshape-bg shape-bg1"
              ><img src="assets/img/d-agency/team/msbg1.png" alt=""
            /></span>
            <span class="mshape-bg shape-bg2"
              ><img src="assets/img/d-agency/team/msbg2.png" alt=""
            /></span>
          </div>
          <div class="dia-team-social">
            <a href="#"><i class="fab fa-facebook-f"></i></a>
            <a href="#"><i class="fab fa-instagram"></i></a>
            <a href="#"><i class="fab fa-linkedin"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
          </div>
        </div>
        <div class="dia-team-text dia-headline text-center pera-content">
          <h3><a href="#">Yusuf Abdulkadir</a></h3>
          <p>Chief Technology Officer</p>
        </div>
      </div>
      <div class="dia-team-pic-text">
        <div class="dia-team-img position-relative">
          <div class="team-mem-img-ei text-center position-relative">
            <img src="assets/img/d-agency/team/tm3.png" alt="" />
            <span class="mshape-bg shape-bg1"
              ><img src="assets/img/d-agency/team/msbg1.png" alt=""
            /></span>
            <span class="mshape-bg shape-bg2"
              ><img src="assets/img/d-agency/team/msbg2.png" alt=""
            /></span>
          </div>
          <div class="dia-team-social">
            <a href="#"><i class="fab fa-facebook-f"></i></a>
            <a href="#"><i class="fab fa-instagram"></i></a>
            <a href="#"><i class="fab fa-linkedin"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
          </div>
        </div>
        <div class="dia-team-text dia-headline text-center pera-content">
          <h3><a href="#">Faisal Abubakar</a></h3>
          <p>President</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End of Team section
        ============================================= -->

<!-- Start of testimonial section
        ============================================= -->
<!-- <section id="dia-testimonial" class="dia-testimonial-section position-relative">
  <div class="tst-side-shape position-absolute" data-parallax='{"y" : 50}'>
    <img src="assets/img/d-agency/shape/tsts1.png" alt="" />
  </div>
  <div class="container">
    <div class="dia-section-title text-center text-capitalize dia-headline">
      <span>Client’s Testimonials</span>
      <h2>Client’s review for our work satisfaction.</h2>
    </div>
    <div class="dia-testimonial_slider-area position-relative">
      <div
        class="test-shape1 position-absolute wow fadeFromRight"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        <img src="assets/img/d-agency/shape/tbg1.png" alt="" />
      </div>
      <div
        class="test-shape2 position-absolute wow fadeFromLeft"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        <img src="assets/img/d-agency/shape/tbg2.png" alt="" />
      </div>
      <div
        id="dia-testimonial_slide"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="dia-testimonial_indicator relative-position">
          <ol class="carousel-indicators">
            <li
              data-target="#dia-testimonial_slide"
              data-slide-to="0"
              class="active"
            >
              <img src="assets/img/d-agency/testimonial/tst1.png" alt="" />
            </li>
            <li data-target="#dia-testimonial_slide" data-slide-to="1">
              <img src="assets/img/d-agency/testimonial/tst2.png" alt="" />
            </li>
            <li data-target="#dia-testimonial_slide" data-slide-to="2">
              <img src="assets/img/d-agency/testimonial/tst3.png" alt="" />
            </li>
          </ol>
        </div>
        <div class="carousel_preview">
          <div class="carousel-inner relative-position">
             
            <div class="carousel-item active">
              <div class="dia-testimonial_content">
                <div class="dia-testimonial_rating ul-li">
                  <ul>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                  </ul>
                </div>
                <div
                  class="
                    dia-testimonial_text
                    relative-position
                    pera-content
                    dia-headline
                  "
                >
                  <p>
                    It’s easy for marketers to brag about how great their product or service is. Writing compelling copy, shooting enticing photos, or even producing glamorous videos are all tactics.
                  </p>
                </div>
                <div class="dia-testimonial_name_designation">
                  <div class="dia-testimonial_meta dia-headline pera-content">
                    <h4>Vincent Agu</h4>
                    <p>Managing Partner</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="carousel-item">
              <div class="dia-testimonial_content">
                <div class="dia-testimonial_rating ul-li">
                  <ul>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                  </ul>
                </div>
                <div
                  class="
                    dia-testimonial_text
                    relative-position
                    pera-content
                    dia-headline
                  "
                >
                  <p>
                    I have been directing web, online marketing and eCommerce
                    applications for over 15 years and this was my first job
                    with Trigonal Technologies Limited. I was completely
                    satisfied and impressed with their level of professionalism,
                    proactive project management and technical capabilities.
                    They are the real deal, the complete package, and now my
                    primary mobile and web development company.
                  </p>
                </div>
                <div class="dia-testimonial_name_designation">
                  <div class="dia-testimonial_meta dia-headline pera-content">
                    <h4>Olumawu College</h4>
                    <p>Principal</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="carousel-item">
              <div class="dia-testimonial_content">
                <div class="dia-testimonial_rating ul-li">
                  <ul>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                    <li><i class="fas fa-star"></i></li>
                  </ul>
                </div>
                <div
                  class="
                    dia-testimonial_text
                    relative-position
                    pera-content
                    dia-headline
                  "
                >
                  <p>
                    Trigonal Technologies Limited did an outstanding job on this
                    project. We will be using their services again and highly
                    recommend them. The team worked well. The product was in the
                    right quality. Feedback was received and issue were
                    corrected rapidly. Their management processes compliment the
                    solid development skills they bring to the table. I'm
                    grateful for being able to work with them on this project.
                  </p>
                </div>
                <div class="dia-testimonial_name_designation">
                  <div class="dia-testimonial_meta dia-headline pera-content">
                    <h4>High Court of Justice, F.C.T Abuja</h4>
                    <p>Director of Probate</p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div class="dia-testimonial_indicator-dot">
          <ol class="carousel-indicators2">
            <li
              data-target="#dia-testimonial_slide"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#dia-testimonial_slide" data-slide-to="1"></li>
            <li data-target="#dia-testimonial_slide" data-slide-to="2"></li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- End of testimonial section
        ============================================= -->

<!-- Start of blog section
        ============================================= -->
<!-- <section id="dia-blog" class="dia-blog-section">
    <div class="container">
        <div class="dia-section-title text-left text-capitalize pera-content dia-headline">
            <span>Our Blogs</span>
            <h2>Our blog & articale</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
        </div>
        <div class="dia-blog-content">
            <div class="row">
                <div class="col-lg-4 wow fadeFromUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div class="dia-blog-img-text">
                        <div class="dia-blog-img">
                            <img src="assets/img/d-agency/blog/b1.jpg" alt="">
                        </div>
                        <div class="dia-blog-text">
                            <span class="dia-blog-tag"><a href="#">Creavive Agency</a></span>
                            <h3><a href="#">10 Secrets About 10 Simple Steps for Blogs Post.</a></h3>
                        </div>
                        <div class="dia-blog-meta">
                            <div class="dia-author-area d-inline-block">
                                <div class="dia-athur-img float-left">
                                    <img src="assets/img/d-agency/blog/ba1.jpg" alt="">
                                </div>
                                <div class="dia-author-name">
                                    <span>Posted By <a href="#">Rinnah</a></span>
                                </div>
                            </div>
                            <div class="dia-date-meta float-right">
                                <a href="#">8 Aug, 2020</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeFromUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                    <div class="dia-blog-img-text">
                        <div class="dia-blog-img">
                            <img src="assets/img/d-agency/blog/b2.jpg" alt="">
                        </div>
                        <div class="dia-blog-text">
                            <span class="dia-blog-tag"><a href="#">Creavive Agency</a></span>
                            <h3><a href="#">10 Secrets About 10 Simple Steps for Blogs Post.</a></h3>
                        </div>
                        <div class="dia-blog-meta">
                            <div class="dia-author-area d-inline-block">
                                <div class="dia-athur-img float-left">
                                    <img src="assets/img/d-agency/blog/ba1.jpg" alt="">
                                </div>
                                <div class="dia-author-name">
                                    <span>Posted By <a href="#">Rinnah</a></span>
                                </div>
                            </div>
                            <div class="dia-date-meta float-right">
                                <a href="#">8 Aug, 2020</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeFromUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                    <div class="dia-blog-img-text">
                        <div class="dia-blog-img">
                            <img src="assets/img/d-agency/blog/b3.jpg" alt="">
                        </div>
                        <div class="dia-blog-text">
                            <span class="dia-blog-tag"><a href="#">Creavive Agency</a></span>
                            <h3><a href="#">10 Secrets About 10 Simple Steps for Blogs Post.</a></h3>
                        </div>
                        <div class="dia-blog-meta">
                            <div class="dia-author-area d-inline-block">
                                <div class="dia-athur-img float-left">
                                    <img src="assets/img/d-agency/blog/ba1.jpg" alt="">
                                </div>
                                <div class="dia-author-name">
                                    <span>Posted By <a href="#">Rinnah</a></span>
                                </div>
                            </div>
                            <div class="dia-date-meta float-right">
                                <a href="#">8 Aug, 2020</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End of Blog section
        ============================================= -->

<!-- Start of newslatter section
        ============================================= -->
<section id="dia-newslatter" class="dia-newslatter-section position-relative">
  <div
    class="newslatter-shape position-absolute n-shape1"
    data-parallax='{"x" : 30}'
  >
    <img src="assets/img/d-agency/newslatter/nv1.png" alt="" />
  </div>
  <div
    class="newslatter-shape position-absolute n-shape2"
    data-parallax='{"x" : -30}'
  >
    <img src="assets/img/d-agency/newslatter/nv2.png" alt="" />
  </div>
  <div
    class="newslatter-shape position-absolute n-shape3"
    data-parallax='{"x" : -30}'
  >
    <img src="assets/img/d-agency/newslatter/nv3.png" alt="" />
  </div>
  <div
    class="newslatter-shape position-absolute n-shape4"
    data-parallax='{"y" : 30}'
  >
    <img src="assets/img/d-agency/newslatter/nv4.png" alt="" />
  </div>
  <div
    class="newslatter-shape position-absolute n-shape5"
    data-parallax='{"y" : 30}'
  >
    <img src="assets/img/d-agency/newslatter/nv5.png" alt="" />
  </div>
  <div
    class="newslatter-shape position-absolute n-shape6"
    data-parallax='{"x" : -30}'
  >
    <img src="assets/img/d-agency/newslatter/nv6.png" alt="" />
  </div>
  <div class="container">
    <div class="dia-newslatter-content">
      <div class="row">
        <div class="col-lg-6">
          <div class="dia-newslatter-text dia-headline pera-content">
            <h3>Like our service? Subscribe now</h3>
            <p>
              Join our subscriber list to get the latest news, update and
              special offers delivered directly in your inbox.
            </p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="dia-newslatter-form relative-position">
            <form #form="ngForm" name="newsletter" (submit)="submitForm()">
              <input
                name="email"
                type="email"
                placeholder="Enter your email address"
                [(ngModel)]="obj.email"
              />
              <div class="nws-button position-absolute text-capitalize">
                <button class="hover-btn" type="submit" name="submit">
                  Subscribe
                </button>
              </div>
              <div class="dia-newslatter-checkbox">
                <input name="#" type="checkbox" value="#" />
                <label>Don’t provide any promotional message.</label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End of newslatter section
                ============================================= -->
