import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { pagesUrl } from '../../pages/pagesUrl';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  obj = { email: null }

  constructor(public http: HttpClient) { }

  ngOnInit(): void {
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    nav: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    navText:["<i class='fas fa-angle-left'></i>","<i class='fas fa-angle-right'></i>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 5
      },
      740: {
        items: 7
      },
      940: {
        items: 8
      }
    },
  }

  submitForm() {
    swal.showLoading();
    this.http.post<any>(pagesUrl.newsletter.add, this.obj).subscribe(
      res => {
        // this.data = res.data;
        swal.fire('Process Complete', res.message, 'success');
        this.resetForm();
      },
      (err) => {
        console.log(err);
        swal.fire('Process Unsuccessful', err.error.message, 'error');
      }
    );
  }

  resetForm() {
    this.obj = {
      email: null
    };
  }

}
