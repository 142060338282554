import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SecondLayoutComponent } from './layouts/second-layout/second-layout.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { OurProductsComponent } from './pages/our-products/our-products.component';
import { OurServicesComponent } from './pages/our-services/our-services.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { QouteComponent } from './pages/qoute/qoute.component';
import { TermsComponent } from './pages/terms/terms.component';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: '', component: DashboardComponent, data: { title: 'processes made simple' } },
      // { path: 'dashboard', component: DashboardComponent, data: { title: 'processes made simple' } },
    ],


  },

  {
    path: '',
    component: SecondLayoutComponent,
    children: [
      { path: 'about-us', component: AboutUsComponent, data: { breadcrumb: 'About Us', title: 'About Us' } },
      { path: 'our-solutions', component: OurProductsComponent, data: { breadcrumb: 'Our Solutions', title: 'Our Solutions' } },
      { path: 'our-services', component: OurServicesComponent, data: { breadcrumb: 'Our Services', title: 'Our Services' } },
      { path: 'contact-us', component: ContactUsComponent, data: { breadcrumb: 'Contact Us', title: 'Contact Us' } },
      { path: 'qoute', component: QouteComponent, data: { breadcrumb: 'Request a Qoute', title: 'Request a Qoute' } },
      { path: 'faq', component: FaqComponent, data: { breadcrumb: 'FAQ', title: 'Frequently Ask Questions' } },
      { path: 'privacy', component: PrivacyComponent, data: { breadcrumb: 'FAQ', title: 'Frequently Ask Questions' } },
      { path: 'terms', component: TermsComponent, data: { breadcrumb: 'FAQ', title: 'Frequently Ask Questions' } },
    ],

  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
