<section class="breadcrumb-area banner-1 bgg">
    <div class="text-block">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 v-center">
                    <breadcrumb #parent>
                        <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb cbd">
                                    <li class="breadcrumb-item active cbdtxt" aria-current="page">
                                        {{ route.displayName }}</li>
                                </ol>
                            </nav>
                        </ng-template>
                    </breadcrumb>

                    <breadcrumb #parent>
                        <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb cbd">
                                    <li class="breadcrumb-item"><a class="al" routerLink="/">Home</a></li>
                                    <li class="breadcrumb-item active cbdtxt2" aria-current="page">
                                        {{ route.displayName }}</li>
                                </ol>
                            </nav>
                        </ng-template>
                    </breadcrumb>
                </div>
            </div>
        </div>
    </div>
</section>