import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SecondLayoutComponent } from './layouts/second-layout/second-layout.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { OurServicesComponent } from './pages/our-services/our-services.component';
import { OurProductsComponent } from './pages/our-products/our-products.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { Header2Component } from './components/header2/header2.component';
import { SubheadComponent } from './components/subhead/subhead.component';
import {BreadcrumbModule} from 'angular-crumbs';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { QouteComponent } from './pages/qoute/qoute.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { FaqComponent } from './pages/faq/faq.component';
import { NgxWhastappButtonModule } from "ngx-whatsapp-button";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    FooterComponent,
    HeaderComponent,
    MainLayoutComponent,
    SecondLayoutComponent,
    AboutUsComponent,
    OurServicesComponent,
    OurProductsComponent,
    BlogComponent,
    ContactUsComponent,
    Header2Component,
    SubheadComponent,
    QouteComponent,
    TermsComponent,
    PrivacyComponent,
    FaqComponent
  ],
  imports: [
    BrowserModule,
    BreadcrumbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    FormsModule,
    HttpClientModule,
    NgxWhastappButtonModule
  ],
  providers: [
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
