<section class="about-sec-app pad-tb pt60 dotbg">
  <div class="container">
    <div class="row">
      <div
        class="col-lg-6 col-md-12 wow fadeFromLeft"
        data-wow-delay="0ms"
        data-wow-duration="1500ms"
      >
        <div class="dia-about-text-area">
          <div class="dia-about-title-text">
            <div
              class="dia-section-title text-left text-capitalize dia-headline"
            >
              <span>We make processes simple</span>
              <h2>Get to know us</h2>
            </div>
            <div class="dia-about-text">
              <p>
                Trigonal Technologies Limited is a fast growing information
                technology company in Nigeria that specializes in providing
                dynamic, robust and powerful software and hardware solutions for
                companies, organizations and individuals. We define, design and
                deliver first class IT solutions to our clients.
              </p>
              <p>
                We provide enhanced flexibility, customized software and
                hardware solutions focused on achieving defined business
                objectives. We strive to develop the best solutions using the
                most appropriate and cost effective technology. With a team of
                dedicated IT professionals, we provide a great return on
                investments and enhance productivity for our clients.
              </p>
              <!-- <p>
                Above all, we believe in our management team who are working
                round the clock to ensure that we never leave our clients
                hanging, giving service and support promptly. “Our job is to
                make your work easier”, thus making our happy clients happier.
              </p> -->
            </div>

            <div class="feature-content-wrap">
              <ul data-tabs="tabs" class="nav nav-tabs feature-tab">
                <li class="nav-item">
                  <a
                    href="#tab6-2"
                    data-toggle="tab"
                    class="nav-link h6 active"
                  >
                    Our Vision
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#tab6-1" data-toggle="tab" class="nav-link h6">
                    Our Mission
                  </a>
                </li>
              </ul>
              <div class="tab-content feature-tab-content">
                <div id="tab6-2" class="tab-pane active">
                  <p class="jus">
                    To become the leading ICT solution provider in Nigeria and
                    Africa at large.
                  </p>
                </div>
                <div id="tab6-1" class="tab-pane">
                  <p class="jus">
                    To become every company, organization and individuals first
                    choice ICT solution provider due to our continually
                    improving, efficient, exceptionally functional solutions at
                    value-based costs. Thus, making our clients’ work easier and
                    making them happier.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-lg-6 wow fadeFromRight"
        data-wow-delay="0ms"
        data-wow-duration="1500ms"
      >
        <div class="funfact">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="funfct srcl1">
                <img
                  src="assets/img/startup.svg"
                  alt="niwax app development template"
                />
                <span class="services-cuntr counter">8</span
                ><span class="services-cuntr">+</span>
                <p>Years Experience</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="funfct srcl2">
                <img
                  src="assets/img/team.svg"
                  alt="niwax app development template"
                />
                <span class="services-cuntr counter">22</span
                ><span class="services-cuntr">+</span>
                <p>Talented Squad</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="funfct srcl3">
                <img
                  src="assets/img/mobile.svg"
                  alt="niwax app development template"
                />
                <span class="services-cuntr counter">120</span
                ><span class="services-cuntr">+</span>
                <p>Apps Developed</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="funfct srcl4">
                <img
                  src="assets/img/computers.svg"
                  alt="niwax app development template"
                />
                <span class="services-cuntr counter">100</span
                ><span class="services-cuntr">%</span>
                <p>Projects Delivered</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="funfct srcl5">
                <img
                  src="assets/img/world.svg"
                  alt="niwax app development template"
                />
                <span class="services-cuntr counter">4</span
                ><span class="services-cuntr">+</span>
                <p>Countries Served</p>
              </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="funfct srcl1">
                <img
                  src="assets/img/deal.svg"
                  alt="niwax app development template"
                />
                <span class="services-cuntr counter">99.9</span
                ><span class="services-cuntr">%</span>
                <p>Client Satisfaction</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Start of Experience section
        ============================================= -->
<section id="dia-exp" class="dia-exp-section oe">
  <div class="container">
    <div class="dia-exp-content">
      <div class="row">
        <div
          class="col-lg-6 col-md-12 wow fadeFromLeft"
          data-wow-delay="0ms"
          data-wow-duration="1500ms"
        >
          <div class="dia-exp-img text-center position-relative">
            <img src="assets/img/d-agency/about/ab2.png" alt="" />
            <div
              class="ab-shape1 position-absolute"
              data-parallax='{"x" : -30}'
            >
              <img src="assets/img/d-agency/shape/as1.png" alt="" />
            </div>
            <div class="ab-shape2 position-absolute" data-parallax='{"x" : 30}'>
              <img src="assets/img/d-agency/shape/as1.png" alt="" />
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 wow fadeFromRight"
          data-wow-delay="300ms"
          data-wow-duration="1500ms"
        >
          <div class="dio-exp-text-area">
            <div class="dia-exp-text">
              <div class="dia-about-title-text">
                <div
                  class="
                    dia-section-title
                    text-left text-capitalize
                    dia-headline
                  "
                >
                  <span>Our Experiences</span>
                  <h2>Leading IT Solution Development Company</h2>
                </div>
                <div class="dia-about-text">
                  Providing quality software services begins with experience and
                  ends with dedication. Trigonal Technologies Limited’
                  professionals have years of experience in design, development,
                  testing of countless software projects and other ICT
                  Solutions. It is this experience, coupled with our dedication
                  to give clients the best quality service possible, which sets
                  us apart from other firms.
                </div>
                <div class="dia-about-text">
                  Trigonal Technologies Limited has a proven track record of
                  providing high quality custom software development, System
                  Networking, CCTV installations, Security Door installations
                  and project management services. Our client list includes
                  Fortune 100 corporations, government agencies, and small
                  businesses.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End of Experience section
                ============================================= -->

<!-- Start of about section
        ============================================= -->
<section id="dia-about" class="dia-about-section aoe">
  <div class="container">
    <div class="dia-about-content">
      <div class="row">
        <div
          class="col-lg-6 col-md-12 wow fadeFromLeft"
          data-wow-delay="0ms"
          data-wow-duration="1500ms"
        >
          <div class="dia-about-text-area">
            <div class="dia-about-title-text">
              <div
                class="dia-section-title text-left text-capitalize dia-headline"
              >
                <span>Area of Expertise</span>
                <h2>Experts Services To Change Business</h2>
              </div>
              <div class="dia-about-text">
                We have experience in many different aspects of the development
                process. Because we are a custom firm, there are no limits on
                what projects we can undertake.
              </div>
            </div>

            <div class="dia-about-list ul-li-block">
              <ul>
                <li>Application Design & Development</li>
                <li>Web Development</li>
                <li>Web-Enabling Applications</li>
                <li>Engineering, Scientific, and Data Collection</li>
                <li>Point-of-Sale and General Business Services</li>
                <li>Systems Integration</li>
                <li>System Architecture and Implementation</li>
                <li>Client/Server Applications and Distributed Systems</li>
                <li>Platform Migrations and Code Porting</li>
                <li>Object-Oriented Design and Analysis</li>
                <li>Project Management Consulting</li>
                <li>Technical Documentation and Training</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 wow fadeFromRight"
          data-wow-delay="300ms"
          data-wow-duration="1500ms"
        >
          <div class="dia-about-img position-relative">
            <img src="assets/img/d-agency/about/ab1.png" alt="" />
            <div
              class="ab-shape1 position-absolute"
              data-parallax='{"x" : -30}'
            >
              <img src="assets/img/d-agency/shape/as1.png" alt="" />
            </div>
            <div class="ab-shape2 position-absolute" data-parallax='{"x" : 30}'>
              <img src="assets/img/d-agency/shape/as1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End of About section
============================================= -->

<div class="crm-call-to-action clearfix">
  <div class="container">
    <div class="crm-call-action-text pera-content">
      <h3 class="mb30">Let's Start a New Project Together</h3>
      <div class="btn btn-outline">
        <a routerLink="/qoute">
          Request A Quote <i class="fas fa-chevron-right fa-icon"></i
        ></a>
      </div>
      <!-- <a href="#">Get 7Day Trail</a> -->
    </div>
  </div>
</div>
