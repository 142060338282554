<section class="enquire-form pad-tb">
  <div class="container">
    <div class="row light-bgs">
      <div class="col-lg-6">
        <div class="common-heading text-l">
          <span>Contact Now</span>
          <h2 class="mt0">Have Question? Write a Message</h2>
        </div>
        <div class="form-block">
          <form #form="ngForm" name="feedback-form" (submit)="submitForm()">
            <div class="fieldsets row">
              <div class="col-md-6"><input type="text" placeholder="Full Name" name="name" [(ngModel)]="obj.fullname">
              </div>
              <div class="col-md-6"><input type="email" placeholder="Email Address" name="email"
                  [(ngModel)]="obj.email"></div>
            </div>
            <div class="fieldsets row">
              <div class="col-md-6"><input type="number" placeholder="Contact Number" name="phone"
                  [(ngModel)]="obj.phone"></div>
              <div class="col-md-6"><input type="text" placeholder="Subject" name="subject" [(ngModel)]="obj.subject"></div>
            </div>
            <div class="fieldsets"><textarea placeholder="Message" name="message" [(ngModel)]="obj.message"></textarea>
            </div>
            <!-- <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="customCheck" name="example1" checked="checked">
              <label class="custom-control-label" for="customCheck">I agree to the <a href="javascript:void(0)">Terms
                  &amp; Conditions</a> of Business Name.</label>
            </div> -->
            <div class="fieldsets mt20"> <button type="submit" name="submit" class="lnk btn-main bg-btn">Submit <i
                  class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></button> </div>
            <p class="trm"><i class="fas fa-lock"></i>We hate spam, and we respect your privacy.</p>
          </form>
        </div>
      </div>
      <div class="col-lg-6 v-center">
        <div class="enquire-image">
          <img src="https://rajeshdoot.com/niwax-demos/html/images/about/hellopic.png" alt="enquire" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</section>