import { environment } from '../../environments/environment';
export let pagesUrl = {
  contact: {
    add: environment.BASE_URL +environment.API_URL + 'contact/add',
    update: environment.BASE_URL +environment.API_URL + 'contact/update',
    list: environment.BASE_URL +environment.API_URL + 'contact/list',
    get: environment.BASE_URL +environment.API_URL + 'contact/get/',
  },

  qoute: {
    add: environment.BASE_URL +environment.API_URL + 'qoute/add',
    update: environment.BASE_URL +environment.API_URL + 'qoute/update',
    list: environment.BASE_URL +environment.API_URL + 'qoute/list',
    get: environment.BASE_URL +environment.API_URL + 'qoute/get/',
  },

  newsletter: {
    add: environment.BASE_URL +environment.API_URL + 'newsletter/add',
    update: environment.BASE_URL +environment.API_URL + 'newsletter/update',
    list: environment.BASE_URL +environment.API_URL + 'newsletter/list',
    get: environment.BASE_URL +environment.API_URL + 'newsletter/get/',
  },
};
