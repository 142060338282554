import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { pagesUrl } from '../pagesUrl';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})

export class ContactUsComponent implements OnInit {

  obj = { fullname: null, email: null, phone: null, subject: null, message: null }

  constructor(public http: HttpClient) { }

  ngOnInit(): void {

  }

  submitForm() {
    swal.showLoading();
    this.http.post<any>(pagesUrl.contact.add, this.obj).subscribe(
      res => {
        // this.data = res.data;
        swal.fire('Process Complete', res.message, 'success');
        this.resetForm();
      },
      (err) => {
        console.log(err);
        swal.fire('Process Unsuccessful', err.error.message, 'error');
      }
    );
  }


  resetForm() {
    this.obj = {
      fullname: null, email: null, phone: null, subject: null, message: null
    };
  }

}
