<!-- Start of header section
        ============================================= -->
<header id="dia-header" class="dia-main-header">
  <div class="container">
    <div class="dia-main-header-content clearfix">
      <div class="dia-logo float-left">
        <a href="/"
          ><img
            src="assets/img/d-agency/logo/logo.png"
            alt="Trigonal Technologies Limited"
            class="logo-c"
        /></a>
      </div>
      <div class="dia-main-menu-item float-right">
        <nav class="dia-main-navigation clearfix ul-li">
          <ul id="main-nav" class="navbar-nav text-capitalize clearfix">
            <li><a href="/">Home</a></li>
            <li><a href="about-us">About Us</a></li>
            <li><a href="our-services">Our Services</a></li>
            <li><a href="our-solutions">Our Solutions</a></li>
            <li><a href="contact-us">Contact Us</a></li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- /desktop menu -->
    <div class="dia-mobile_menu relative-position">
      <div class="dia-mobile_menu_button dia-open_mobile_menu">
        <i class="fas fa-bars"></i>
      </div>
      <div class="dia-mobile_menu_wrap">
        <div class="mobile_menu_overlay dia-open_mobile_menu"></div>
        <div class="dia-mobile_menu_content">
          <div class="dia-mobile_menu_close dia-open_mobile_menu">
            <i class="far fa-times-circle"></i>
          </div>
          <div class="m-brand-logo text-center">
            <a href="/"
              ><img src="assets/img/d-agency/logo/logo.png" alt=""
            /></a>
          </div>
          <nav class="dia-mobile-main-navigation clearfix ul-li">
            <ul id="m-main-nav" class="navbar-nav text-capitalize clearfix">
              <li><a href="/">Home</a></li>
              <li><a href="about-us">About Us</a></li>
              <li><a href="our-services">Our Services</a></li>
              <li><a href="our-solutions">Our Solutions</a></li>
              <li><a href="contact-us">Contact Us</a></li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- /mobile-menu -->
    </div>
  </div>
</header>
<!-- End of header section
        ============================================= -->
