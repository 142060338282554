<!-- Start of Footer  section
        ============================================= -->
<section id="dia-footer" class="dia-footer-section position-relative">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="dia-footer-widget pera-content dia-headline clearfix">
          <div class="dia-footer-logo">
            <img
              src="assets/img/d-agency/logo/logo.png"
              alt="Trigonal Technologies Limited"
            />
          </div>
          <p>
            We provide enhanced flexibility, customized software and hardware
            solutions focused on achieving defined business objectives.
          </p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="dia-footer-widget dia-headline ul-li-block clearfix">
          <h3 class="dia-widget-title">Useful link:</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="about-us">About Us</a></li>
            <li><a href="our-services">Our Services</a></li>
            <li><a href="our-solutions">Our Solutions</a></li>
            <li><a href="contact-us">Contact Us</a></li>
            <li><a href="faq">FAQ</a></li>
            <li><a href="terms">Terms & Conditions </a></li>
            <li><a href="privacy">Privacy </a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="dia-footer-widget dia-headline ul-li-block clearfix">
          <h3 class="dia-widget-title">Contact:</h3>
          <h4>
            <i class="fas fa-phone"></i>
            Call Us:
            <span class="phadd"
              >+2348095888885<br />+971504996426<br />+2347037715235</span
            >
          </h4>
          <h4>
            <i class="fas fa-map-marker-alt"></i>
            Visit Us
            <span class="phadd"
              >Suite 112 – 113 Adamawa Plaza, CBD 1, FCT Abuja, Nigeria</span
            >
          </h4>
          <!-- <div class="download-btn">
                                <a href="#"><img src="assets/img/d-agency/logo/app1.png" alt=""></a>
                                <a href="#"><img src="assets/img/d-agency/logo/gp1.png" alt=""></a>
                            </div> -->
          <!-- <div class="dia-footer-social">
                                <a href="#"><i class="fab fa-facebook-f "></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                                <a href="#"><i class="fab fa-linkedin"></i></a>
                            </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="dia-footer-copyright">
    <div class="container">
      <div class="dia-footer-copyright-content">
        <div class="row">
          <div class="col-md-4">
            <div class="dia-copyright-text pera-content">
              <p>© 2021 <a href="/">Trigonal Technologies Limited</a></p>
            </div>
          </div>
          <div class="col-md-8">
            <div class="ismsocial">
              <a href="#"><i class="fab fa-facebook-f"></i></a>
              <a href="#"><i class="fab fa-twitter"></i></a>
              <a href="#"><i class="fab fa-instagram"></i></a>
              <a href="#"><i class="fab fa-linkedin"></i></a>
            </div>
            <!-- <div class="dia-copyright-menu">
                                    <a href="contact-us">Help</a>
                                    <a href="terms">Terms & Conditions </a>
                                    <a href="privacy">Privacy </a>
                                </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dia-footer-shape3 position-absolute">
    <img src="assets/img/d-agency/shape/diamap.png" alt="" />
  </div>
</section>
<!-- End of Footer  section
        ============================================= -->
