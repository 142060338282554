<section class="enquire-form pad-tb">
  <div class="container">
    <div class="row light-bgs">
      <div class="col-lg-12">
        <div class="common-heading text-l">
          <h2 class="mt0">Get Free Quotation</h2>
          <span>We transform ideas into tangible entities with meaningful application. Let us hear your idea and
            together we can build something new.</span>
        </div>
        <div class="form-block mgtop">
          <form #form="ngForm" name="feedback-form" (submit)="submitForm()">
            <div class="fieldsets row">
              <div class="col-md-6"><input type="text" placeholder="Full Name" name="name" [(ngModel)]="obj.fullname">
              </div>
              <div class="col-md-6"><input type="email" placeholder="Email Address" name="email"
                  [(ngModel)]="obj.email"></div>
            </div>

            <div class="fieldsets row">
              <div class="col-md-6"><input type="number" placeholder="Phone Number" name="phone"
                  [(ngModel)]="obj.phone"></div>
              <div class="col-md-6"><input type="text" placeholder="Company Name" name="comapanyname"
                  [(ngModel)]="obj.comapanyname"></div>
            </div>

            <div class="fieldsets row">
              <div class="col-md-6"><input type="text" placeholder="Location" name="location"
                  [(ngModel)]="obj.location"></div>
              <div class="col-md-6"><input type="text" placeholder="Estimated Budget" name="companyname"
                  [(ngModel)]="obj.budget"></div>
            </div>

            <div class="fieldsets"><textarea placeholder="Write a synopsis of your project / idea*" name="message"
                [(ngModel)]="obj.message"></textarea>
            </div>
            <div class="fieldsets mt20"> <button type="submit" name="submit" class="lnk btn-main bg-btn">Submit <i
                  class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></button> </div>
            <p class="trm"><i class="fas fa-lock"></i>We'll ensure, all the information provided by you will not be
              shared with anyone and your details are completely safe. We respect your privacy.</p>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>